@tailwind base;
@tailwind components;
@tailwind utilities;

/*  ----------------------------------------------------
0. IMPORT GOOGLE FONTS
-------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap");

/* ------------------------------------
    1. GENERAL STYLES
------------------------------------ */
.typewriter > h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid white; /* The typewriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation:
    typing 3.5s steps(43, end),
    blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
